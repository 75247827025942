import type { Token } from "@stripe/stripe-js";
import * as Sentry from "@sentry/vue";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import {
  ONEOFF,
  REGULAR,
  STRIPE_PERCENTAGE,
  STRIPE_FIXED,
  FREQUENCY_FORTNIGHTLY,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_CASH,
} from "~/lib/constants";

export const useBookingStore = defineStore(
  "bookingStore",
  () => {
    const country = ref<string>("");
    const type = ref("Domestic");
    const step = ref<number>(1);
    const is_dva = ref<boolean>(false);
    const service = ref<string | null>(null);
    const frequency = ref<string | null>(null);
    const oneoff_type = ref<string | null>(null);
    const hours = ref<number | null>(null);
    const days = ref<number | null>(null);
    const is_ndis = ref<number | null>(null);
    const is_ndis_confirmed = ref<boolean>(false);
    const preferred_days = ref<string[] | null>();
    const start_date = ref<Date | null>(null);
    const comments = ref<string | null>(null);
    const first_name = ref<string | null>(null);
    const last_name = ref<string | null>(null);
    const email = ref<string | null>(null);
    const phone = ref<string | null>(null);
    const suburb = ref<any | undefined>(undefined);
    const address = ref<string | null>(null);
    const address_suburb = ref<string | null>(null);
    const address_state = ref<string | null>(null);
    const address_postcode = ref<string | null>(null);
    const payment_method = ref<string | null>(null);
    const token = ref<Token | null>(null);
    const valid = ref<Number[]>([]);
    const suburb_not_found = ref<boolean>(false);
    const submitting = ref<boolean>(false);
    const api_error = ref<any | null>(null);

    function setSuburb(value: any) {
      suburb.value = value;
    }

    function setAddress(value: string) {
      address.value = value;
    }

    function setAddressSuburb(value: string) {
      address_suburb.value = value;
    }

    function setAddressState(value: string) {
      address_state.value = value;
    }

    function setAddressPostcode(value: string) {
      address_postcode.value = value;
    }

    function setCountry(value: string) {
      country.value = value;
    }

    function setStep(value: number) {
      step.value = value;
    }

    function nextStep() {
      if (step.value >= 6) {
        return;
      }

      step.value++;
    }

    function setFirstName(value: string) {
      first_name.value = value;
    }

    function setLastName(value: string) {
      last_name.value = value;
    }

    function previousStep() {
      if (step.value <= 1) {
        return;
      }

      step.value--;
    }

    function setHours(value: number | null) {
      hours.value = value;
    }

    function setDays(value: number | null) {
      days.value = value;
    }

    function setService(value: string) {
      service.value = value;

      if (value !== ONEOFF) {
        oneoff_type.value = null;
      }
    }

    function setFrequency(value: string) {
      frequency.value = value;
    }

    function setComments(value: string) {
      comments.value = value;
    }

    function setOneoffType(value: string) {
      oneoff_type.value = value;
    }

    function setPreferredDays(value: []) {
      preferred_days.value = value;
    }

    function setStartDate(value: Date) {
      start_date.value = value;
    }

    function setEmail(value: string) {
      email.value = value;
    }

    function setPhone(value: string) {
      phone.value = value;
    }

    function setPaymentMethod(value: string) {
      payment_method.value = value;
    }

    function isRegular() {
      return service.value === REGULAR;
    }

    function isOneoff() {
      return service.value === ONEOFF;
    }

    function getHourlyRate(service: string) {
      if (!suburb.value) {
        return null;
      }

      if (!service) {
        return;
      }

      return suburb.value.rates[String(service).toLowerCase()]["hourly_rate"];
    }

    function getAgencyFee(service: string) {
      if (!suburb.value) {
        return null;
      }

      return suburb.value.rates[String(service).toLowerCase()]["agency_fee"];
    }

    function getMinimumHours(service: string) {
      if (!suburb.value) {
        return null;
      }

      if (!service) {
        return;
      }

      return suburb.value.minimum_hours[String(service).toLowerCase()];
    }

    function getBaseRate(service: string) {
      if (!service) {
        return;
      }

      return (
        getMinimumHours(service) * getHourlyRate(service) +
        getAgencyFee(service)
      ).toFixed(2);
    }

    function usingCreditCard() {
      return payment_method.value === PAYMENT_METHOD_CREDIT_CARD;
    }

    function usingCash() {
      return payment_method.value === PAYMENT_METHOD_CASH;
    }

    function hasServiceFee() {
      return isRegular() && usingCreditCard();
    }

    function getTotal() {
      if (!suburb.value || !service.value || !type.value || !hours.value) {
        return (0.0).toFixed(2);
      }

      const rate = suburb.value.rates[service?.value.toLowerCase()];

      return (rate.hourly_rate * hours.value + rate.agency_fee).toFixed(2);
    }

    function getProcessingFee() {
      const fees =
        (getTotal() * (STRIPE_PERCENTAGE / 100) + STRIPE_FIXED) * 1.1;

      return (fees * 0.65).toFixed(2);
    }

    function setToken(value: Token) {
      token.value = value;
    }

    function isAU() {
      return country.value === "au";
    }

    function isNZ() {
      return country.value === "nz";
    }

    function isRegularEnabled() {
      return suburb.value?.regular_enabled;
    }

    function isCatchupEnabled() {
      return suburb.value?.catchup_enabled;
    }

    function isOneoffEnabled() {
      return suburb.value?.oneoff_enabled;
    }

    function getContactNumber() {
      if (isAU()) {
        return "1300 364 646";
      }

      return "0800 663 987";
    }

    function canUseCreditCard() {
      return (
        suburb.value?.credit_cards_enabled &&
        service.value !== ONEOFF &&
        type.value === "Domestic"
      );
    }

    function getValues() {
      return {
        country: country.value,
        type: type.value,
        is_ndis: is_ndis.value,
        is_dva: is_dva.value,
        service: service.value,
        frequency: frequency.value,
        oneoff_type: oneoff_type.value,
        hours: hours.value,
        days: days.value,
        preferred_days: preferred_days.value,
        start_date: start_date.value,
        comments: comments.value,
        first_name: first_name.value,
        last_name: last_name.value,
        email: email.value,
        phone: phone.value,
        suburb: suburb.value,
        address: address.value,
        address_suburb: address_suburb.value,
        address_state: address_state.value,
        address_postcode: address_postcode.value,
        payment_method: payment_method.value,
      };
    }

    async function submit(): Promise<any> {
      submitting.value = true;
      api_error.value = null;

      const formData = {
        country: country.value?.toLowerCase(),
        type: type.value,
        is_ndis: false,
        is_dva: false,
        service: service.value,
        frequency: frequency.value,
        oneoff_type: oneoff_type.value,
        hours: hours.value,
        days: days.value ?? 1,
        preferred_days: preferred_days.value,
        start_date: dayjs(start_date.value).format("D/MM/YYYY"),
        message: comments.value,
        first_name: first_name.value,
        last_name: last_name.value,
        email: email.value,
        phone: phone.value,
        suburb_id: suburb.value.id,
        address: address.value,
        suburb: address_suburb.value,
        state: address_state.value,
        postcode: address_postcode.value,
        payment_method: payment_method.value,
        stripe_token: token.value?.id,
      };

      return new Promise(async (resolve, reject) => {
        return await useApi()
          .post("/booking", formData)
          .then((response) => {
            Sentry.setTag("booking_created", response.data.data.unique_id);
            resolve(response);
          })
          .catch((error) => {
            api_error.value = error;

            if (error.response?.data) {
              Sentry.addBreadcrumb({
                category: "booking",
                message: "Error submitting booking",
                level: "debug",
                data: error.response?.data,
              });
            }

            Sentry.captureException(error);
            reject(error);
          })
          .finally(() => {
            submitting.value = false;
          });
      });
    }

    watch(
      () => frequency.value,
      (value) => {
        if (value === FREQUENCY_FORTNIGHTLY) {
          days.value = 1;
        }
      }
    );

    return {
      setStep,
      suburb,
      setSuburb,
      address,
      setAddress,
      address_suburb,
      setAddressSuburb,
      address_state,
      setAddressState,
      address_postcode,
      setAddressPostcode,
      email,
      setEmail,
      phone,
      setPhone,
      first_name,
      setFirstName,
      last_name,
      setLastName,
      country,
      setCountry,
      comments,
      setComments,
      step,
      service,
      frequency,
      setFrequency,
      oneoff_type,
      hours,
      days,
      setDays,
      is_ndis,
      is_ndis_confirmed,
      preferred_days,
      start_date,
      nextStep,
      previousStep,
      setHours,
      setService,
      setOneoffType,
      setPreferredDays,
      setStartDate,
      payment_method,
      setPaymentMethod,
      token,
      setToken,
      getTotal,
      isRegular,
      isOneoff,
      getHourlyRate,
      getAgencyFee,
      getMinimumHours,
      getBaseRate,
      getValues,
      isAU,
      isNZ,
      isRegularEnabled,
      isOneoffEnabled,
      isCatchupEnabled,
      getContactNumber,
      canUseCreditCard,
      valid,
      hasServiceFee,
      usingCreditCard,
      usingCash,
      getProcessingFee,
      submit,
      submitting,
      api_error,
      suburb_not_found,
    };
  },
  {
    persist: false,
  }
);

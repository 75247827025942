<script setup lang="ts">
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useBookingStore } from "~/store/";
import { CheckIcon } from "lucide-vue-next";

const booking = useBookingStore();
</script>

<template>
  <RadioGroup class="gap-y-3" v-model="booking.step" disabled>
    <div class="flex items-center space-x-2">
      <div
        class="step relative flex items-center justify-center"
        :class="{
          completed: booking.valid.includes(1),
        }"
      >
        <RadioGroupItem id="location" :value="1" />
        <CheckIcon class="absolute inset-0 m-auto w-2 h-2 text-white" />
      </div>
      <Label class="text-md" for="location">Location</Label>
    </div>
    <div class="flex items-center space-x-2">
      <div
        class="step relative flex items-center justify-center"
        :class="{
          completed: booking.valid.includes(2),
        }"
      >
        <RadioGroupItem id="cleaning-service" :value="2" />
        <CheckIcon class="absolute inset-0 m-auto w-2 h-2 text-white" />
      </div>
      <Label class="text-md" for="cleaning-service">Cleaning Service</Label>
    </div>
    <div class="flex items-center space-x-2">
      <div
        class="step relative flex items-center justify-center"
        :class="{
          completed: booking.valid.includes(3),
        }"
      >
        <RadioGroupItem id="about-the-clean" :value="3" />
        <CheckIcon class="absolute inset-0 m-auto w-2 h-2 text-white" />
      </div>
      <Label class="text-md" for="about-the-clean">About the Clean</Label>
    </div>
    <div class="flex items-center space-x-2">
      <div
        class="step relative flex items-center justify-center"
        :class="{
          completed: booking.valid.includes(4),
        }"
      >
        <RadioGroupItem id="your-details" :value="4" />
        <CheckIcon class="absolute inset-0 m-auto w-2 h-2 text-white" />
      </div>
      <Label class="text-md" for="your-details">Your Details</Label>
    </div>
    <div class="flex items-center space-x-2">
      <div
        class="step relative flex items-center justify-center"
        :class="{
          completed: booking.valid.includes(5),
        }"
      >
        <RadioGroupItem id="complete-booking" class="step" :value="5" />
        <CheckIcon class="absolute inset-0 m-auto w-2 h-2 text-white" />
      </div>
      <Label class="text-md" for="complete-booking">Complete Booking</Label>
    </div>
  </RadioGroup>
</template>

<style>
[role="radiogroup"] {
  label {
    @apply peer-disabled:opacity-100;
  }
}

.step {
  > svg {
    @apply hidden;
  }

  > button[role="radio"] {
    @apply bg-primary-light border-primary-soft disabled:opacity-100 disabled:cursor-default;
  }

  &.completed {
    @apply text-success;

    > button[role="radio"] {
      @apply bg-success ring-2 ring-success/20 border-none text-transparent;
    }

    > svg {
      @apply block;
    }
  }
}
</style>

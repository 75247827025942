<script setup lang="ts">
import { useBookingStore } from "~/store/";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";

const booking = useBookingStore();

watch(
  () => booking.api_error,
  (value) => {
    if (value) {
      nextTick(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <Card v-if="booking.api_error" class="flash-destructive">
    <CardHeader>
      <CardTitle>Error</CardTitle>
      <CardDescription>
        Please fix the errors and submit again.
      </CardDescription>
    </CardHeader>
    <CardContent>
      <div
        v-for="([key, errors], index) in Object.entries(
          booking.api_error.response.data.errors
        )"
        :key="index"
      >
        <div v-for="error in errors" class="text-destructive">
          {{ error }}
        </div>
      </div>
    </CardContent>
    <CardFooter>
      <h3>
        If you're having trouble, call us on
        <a :href="`tel:${booking.getContactNumber()}`" class="text-primary">
          {{ booking.getContactNumber() }}
        </a>
      </h3>
    </CardFooter>
  </Card>
</template>

<style>
@keyframes flash-destructive {
  0% {
    @apply bg-destructive/10 border-destructive;
  }
  50% {
    @apply bg-transparent border-border;
  }
  100% {
    @apply bg-destructive/10 border-destructive;
  }
}

.flash-destructive {
  animation: flash-destructive 500ms ease 3;
}
</style>

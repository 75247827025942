import axios from "axios";

export function useApi(options = {}) {
    const config = useRuntimeConfig();

    let headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };

    if (config.public.cfAccessClientId && config.public.cfAccessClientSecret) {
        headers = {
            ...headers,
            "CF-Access-Client-Id": config.public.cfAccessClientId,
            "CF-Access-Client-Secret": config.public.cfAccessClientSecret,
        };
    }

    const api = axios.create({
        baseURL: `${config.public.apiBase}/api/`,
        headers,
        ...options,
    });

    return api;
}

<script setup lang="ts">
import { Loader2 } from "lucide-vue-next";
import { useBookingStore } from "~/store";
import { captureException } from "@sentry/vue";

const store = useBookingStore();

const buttonText = computed(() => {
	if (store.step === 5) {
		return "Submit";
	}

	return "Next"
});

const next = () => {
  const form: HTMLFormElement | null = document.getElementById(
    "form"
  ) as HTMLFormElement | null;
  if (form) {
    if (typeof form.requestSubmit === "function") {
      form.requestSubmit();
    } else {
      form.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  } else {
    captureException(new Error("Form not found"));
  }
};
</script>

<template>
  <div class="flex items-center gap-x-3">
    <Button
      id="back"
      v-if="store.step > 1"
			tabindex="-1"
      variant="outline"
      size="lg"
      @click.prevent="store.previousStep"
      class="w-full border-border"
    >
      Back
    </Button>
    <Button
			v-if="store.step !== 1"
      id="next"
      type="submit"
      @click.prevent="next"
      size="lg"
      class="w-full"
      :disabled="store.submitting"
    >
      <Loader2 class="w-4 h-4 mr-2 animate-spin" v-show="store.submitting" />
      {{ buttonText }}
    </Button>
  </div>
</template>
